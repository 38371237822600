import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import EpisodeCard from '../EpisodeCard'

function Intro({ latest, loading }) {
  return (
    <div className='intro'>
      <Row>
        <Col md={4} lg={4} className='welcome'>
          <div className='heading'>Welcome to a new podcast experiment</div>
          <div className='divider' />
          <div className='para-text'>
            <p>
              This new podcast series is focused on lifting the veil on the
              business side of Bitcoin.
            </p>
            <br />
            <p>
              <i>The Biz features</i>{' '}
              <span className='highlight'>John Carvalho</span> interviewing
              Bitcoin professionals about their valuable experiences as
              founders, product designers, lawyers, and entrepreneurs building
              the Bitcoin products of tomorrow.
            </p>
            <br />
            <p>
              Each episode is locked behind a “crowdwall” that anyone can help
              unlock for everyone.
            </p>
            <br />
            <AnchorLink to='/about#crowdwall' className='crowdwall'>
              Learn About Crowdwalls
            </AnchorLink>
          </div>
        </Col>
        <Col md={8} bottom='xs'>
          <div className='latest-episode'>
            <div className='heading'>Latest episode</div>
            <div className='divider' />
            <EpisodeCard episode={latest} loading={loading} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Intro
