import { faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import ProgressBar from '../core/ProgressBar'

const GoalProgress = ({ goal, isMobile, percent }) => (
  <ProgressBar
    textStart={`Goal: ${goal} sats`}
    textEnd={
      <div className='textEnd'>
        <FontAwesomeIcon icon={faUnlock} />
        <span>
          {percent.toFixed(1)}% {!isMobile && 'Unlocked'}
        </span>
      </div>
    }
    perc={percent}
  />
)

export default GoalProgress
