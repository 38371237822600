import _last from 'lodash/last'
import _size from 'lodash/size'
import _slice from 'lodash/slice'
import React, { useContext } from 'react'

import Episodes from '../components/Episodes'
import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Intro from '../components/Intro'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'
import AppContext from '../context/AppContext'
import useWindowSize from '../hooks/useWindowSize'

const IndexPage = ({ location }) => {
  const { loading, podcasts } = useContext(AppContext)
  const { isMD: isMobile } = useWindowSize()
  const latest = _last(podcasts)
  const rest = podcasts.filter(p => p.id !== latest.id)

  return (
    <Layout location={location}>
      <SEO />
      <PageContent>
        <Header />
        <br />
        <Intro latest={latest} loading={loading} />
        <Episodes
          podcasts={rest}
          loading={loading}
          heading='More Episodes'
          showLoader
        />
        {!isMobile && <br />}
        <FooterMenu showSocialLabel={isMobile} />
      </PageContent>
    </Layout>
  )
}

export default IndexPage
