import React from 'react'

import Skeleton from './Skeleton'

export default function CardSkeleton() {
  return (
    <div>
      <Skeleton width='100%' height={5} />
      <Skeleton width='0%' height={2} />
      <div style={{ display: 'flex' }}>
        <Skeleton width='40%' height={30} />
        <Skeleton width='70%' height={30} />
      </div>
      <Skeleton width='0%' height={2} />
      <Skeleton width='100%' height={5} />
    </div>
  )
}
